/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { resolveImage, resolveAboveCta } from "@dataResolvers";
import { t } from "@utils";
import { Page as PageTemplate } from "@pageTemplates";
import QueryContainer from "@templates/QueryContainer";

export const query = graphql`
  query ReceiversQuery($siteId: [Craft_QueryArgument]) {
    craft {
      entry(section: "receiversIndex", siteId: $siteId) {
        title
        lang: language
        ... on Craft_receiversIndex_receiversIndex_Entry {
          #Hero
          heroTitle
          entrySubtitle
          #Sections
          intro
          heading
          subHeading
          componentsAccessories {
            ... on Craft_componentAccessories_componentAccessory_Entry {
              title
              price
            }
          }
          #Above Footer CTAs
          aboveFooterCtaImageBlocks {
            ...AboveFooterFragment
          }
          #Meta
          metaDescription
          metaFeaturedImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
      entries(section: "receivers", siteId: $siteId) {
        ... on Craft_receivers_receiver_Entry {
          title
          url
          shortName
          metaDescription
          specs {
            ... on Craft_specs_TableRow {
              heading
              value
            }
          }
          metaFeaturedImage(withTransforms: ["carouselExtraLarge"]) {
            width(transform: "carouselExtraLarge")
            height(transform: "carouselExtraLarge")
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url(transform: "carouselExtraLarge")
          }
          productImage(withTransforms: ["carouselExtraLarge"]) {
            width(transform: "carouselExtraLarge")
            height(transform: "carouselExtraLarge")
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url(transform: "carouselExtraLarge")
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry, entries }) => {
  if (!entry) return null;
  const {
    title,
    heroTitle,
    entrySubtitle,
    intro,
    heading,
    subHeading,
    componentsAccessories,
    aboveFooterCtaImageBlocks,
    metaFeaturedImage,
    metaDescription,
    lang,
  } = entry;

  return {
    title,
    hero: {
      title: heroTitle || title,
      subtitle: entrySubtitle,
      image: resolveImage(metaFeaturedImage),
    },
    intro: {
      copy: intro,
    },
    receivers: entries?.map(receiver => {
      return {
        heading: receiver?.title,
        summary: receiver?.metaDescription,
        image: resolveImage(
          receiver?.productImage || receiver?.metaFeaturedImage
        ),
        link: {
          text: `${t("Explore", lang)} ${
            receiver?.shortName || receiver?.title
          }`,
          url: receiver?.url,
        },
        specs: receiver?.specs,
        width: entries?.length === 4 ? "half" : "third",
        centerTitle: true,
        fullWidthImage: true,
      };
    }),
    cards: resolveAboveCta(aboveFooterCtaImageBlocks),
    accessories: {
      heading,
      subheading: subHeading,
      componentsAccessories,
    },
    meta: {
      title,
      metaFeaturedImage: resolveImage(metaFeaturedImage),
      metaDescription,
    },
  };
};

const Page = ({ data, pageContext }) => (
  <QueryContainer
    data={data}
    pageContext={pageContext}
    PageTemplate={PageTemplate}
    dataResolver={dataResolver}
  />
);

export default Page;
